import axios from "axios";

const apiClient = axios.create({
    baseURL: "https://loop.linqode.com/api/public/api/",
    withCredentials: false,
    headers: {
        Accept: "application/json",
        'Content-Type': 'application/json'
    }
})

export default {

    //Banners
    getBanners(){
        return apiClient.get('news/listAll')
    },

    //Applications
    getApplications(){
        return apiClient.get('application/listAll')
    },

    //Functions
    getFunctions(){
        return apiClient.get('function/listAll')
    },

     //Brands
     getBrands(){
        return apiClient.get('brand/listAll')
    },

    //Products
    getProducts(app_id, func_id, brand_id){
        return apiClient.get("products/list/"+app_id+"/"+func_id+"/"+brand_id)
    },

    //SingleProduct
    getSingleProduct(id){
        return apiClient.get("products/listSingle/"+id)
    },
}