<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="terms-container">
        <v-toolbar dark color="primary">
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark v-on:click="changeTerms('')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
          <h2>Política Protecção de Dados</h2>
          <div class="text">
            <h3>Preambulo</h3>
            <p>
              A politica de protecção de dados descrita neste documento
              aplica-se a todos os dados fornecidos à LOOP Projecto e Comércio
              Internacional Lda (doravante denominado “LOOP”), com sede na Rua
              D. João V Nº10 2ºDto, 1250-090 Lisboa, com o NIPC 501247467, e com
              o e-mail <a href="mailto:geral@loop.pt">geral@loop.pt</a>.
            </p>
            <p>
              A LOOP é responsável pela recolha e tratamento dos dados pessoais
              que forem recolhidos na sua atividade, comprometendo-se a
              respeitar a privacidade dos seus titulares, assegurando a
              confidencialidade e segurança dos dados recebidos, em estreito
              cumprimento da legislação em vigor, designadamente a Lei n.º
              58/2019, de 8 de agosto e o Regulamento (EU) n.º 679/2016, de 27
              de abril (regulamento geral sobre a proteção de dados).
            </p>
            <p>
              Esta política de privacidade descreve as nossas práticas de
              tratamento das suas informações pessoais ou da sua empresa.
            </p>
            <p>A LOOP aconselha-o a ler esta política de privacidade.</p>
            <p>
              A utilização do website da LOOP pressupõe que os utilizadores
              conhecem e aceitam os termos e condições da presente Política de
              Privacidade.
            </p>
            <h3>Recolha de dados pessoais</h3>
            <p>
              A LOOP recolhe dados para resposta a pedidos de contacto e assim
              proporcionar esclarecimento sobre os nossos produtos e serviços.
              Estes dados são recolhidos através do preenchimento do formulário
              de contacto disponível no nosso site, email ou telefone, sendo
              fornecidos diretamente pelo utilizador. Não obstante, a LOOP
              poderá obter alguns desses dados através da utilização de
              tecnologias como cookies, ou de terceiros, desde que tenha sido
              consentida a transmissão dos dados.
            </p>
            <h3>Tipos de utilizações dos dados pessoais</h3>
            <p>
              Os dados recolhidos serão utilizados na gestão da relação
              contratual com a LOOP, nomeadamente, a comunicação com clientes,
              comunicação de informações, processamento de encomendas bem como
              na promoção e divulgação de outros serviços e produtos
              disponíveis.
            </p>
            <p>
              Com vista à execução da relação contratual os dados pessoais
              recolhidos poderão ser fornecidos a terceiros, nomeadamente
              empresas Parceiras e transportadoras de mercadorias. Sendo que
              nestas situações a Loop apenas fornecerá os dados estritamente
              necessários para esse fim e assegurará que o fornecimento de dados
              se realiza com segurança.
            </p>
            <p>
              O titular dos dados autoriza expressamente, que os seus dados
              pessoais, sejam ainda utilizados pela LOOP para efeitos de
              marketing direto, através da utilização de sistemas automatizados
              de chamada de comunicação, telecópia, correio eletrónico, SMS,
              SEM, MMS e outros meios similares. O titular dos dados tem a
              possibilidade de se desvincular destas comunicações bastando para
              o efeito efectuar esse pedido para o endereço de e-mail
              <a href="mailto:geral@loop.pt">geral@loop.pt</a>.
            </p>
            <p>
              A Loop trata e conserva os seus dados pessoais apenas pelo período
              de tempo necessário para o cumprimento das finalidades que
              motivaram a sua recolha e conservação supra referidos, e sempre de
              acordo com a lei, ou até que o titular exerça o direito de
              oposição, o direito ao apagamento dos seus dados ou retire o
              consentimento previamente dado.
            </p>
            <h3>Medidas de segurança</h3>
            <p>
              A LOOP garante a proteção dos seus dados pessoais contra qualquer
              perda, difusão, destruição, alteração, acesso, ou utilização
              indevida ou não autorizada adotando para o efeito todas as medidas
              legalmente exigidas para o efeito.
            </p>
            <p>
              Para estes efeitos, a LOOP possui medidas técnicas e organizativas
              que visam garantir a segurança da informação e protegê-la contra
              quaisquer atos deliberados ou acidentais que possam provocar a sua
              manipulação, difusão, destruição, perda ou utilização não
              autorizada.
            </p>
            <h3>Websites externos</h3>
            <p>
              A LOOP poderá fornecer ligações a websites de terceiros, sendo que
              a presente política de privacidade somente se aplica ao seu
              website.
            </p>
            <p>
              A Loop não se responsabiliza pelo conteúdo acedido através de
              qualquer hiperligação que leve o titular de dados pessoais a
              navegar fora do website da Loop, bem como pelo tratamento de dados
              pessoais efetuado nessas hiperligações. Assim, aconselhamo-lo a
              rever as políticas de privacidade apresentadas nos websites
              externos.
            </p>
            <h3>Cookies</h3>
            <p>
              O nosso website pode utilizar cookies. Os cookies ajudam-nos a
              fornecer uma melhor experiência do utilizador ao medir as áreas
              dos sites na Web que suscitam um maior interesse.
            </p>
            <p>
              Ao utilizar este website, está a consentir na recolha e utilização
              das informações.
            </p>
            <h3>Direitos do titular dos dados</h3>
            <p>
              Nos termos do Regulamento Geral sobre Proteção de Dados, o titular
              dos dados tem o direito de solicitar o acesso aos dados pessoais
              que lhe digam respeito, bem como a sua retificação ou o seu
              apagamento. O titular dos dados tem ainda o direito de exigir, em
              determinadas circunstâncias, a limitação do tratamento dos dados
              pessoais, assim como tem o direito de se opor ao tratamento, o
              direito de portabilidade dos dados e o direito de apresentar
              reclamação a uma autoridade de controlo.
            </p>
            <p>
              O titular dos dados tem ainda o direito de retirar o consentimento
              dado ao tratamento dos mesmos, em qualquer altura, sem comprometer
              a licitude do tratamento efetuado com base no consentimento
              previamente dado.
            </p>
            <p>
              Precisamos da sua ajuda para nos certificarmos de que os seus
              dados pessoais estão corretos, nos nossos sistemas. Solicitamos
              que nos informe de qualquer alteração de nome, endereço, cargo,
              número de telefone ou endereço de correio eletrónico para
              <a href="mailto:geral@loop.pt">geral@loop.pt</a>.
            </p>
            <h3>Atualização de políticas</h3>
            <p>
              Todas as atualizações desta política serão publicadas nesta
              página.
            </p>
            <p>Última atualização: Junho 2020</p>
            <p>
              Precisa de ajuda para efetuar um pedido de atualização, acesso ou
              cancelamento da subscrição? Contacte-nos através do e-mail
              <a href="mailto:geral@loop.pt">geral@loop.pt</a>.
            </p>
          </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
    };
  },
  methods:{
      changeTerms: async function (terms) {
      this.$emit("open-terms", terms);
    },
  }
};
</script>

<style lang="scss" scoped>

h2{
    padding-top: 50px;
}

.text{
    padding: 0 5%;
    padding-bottom: 50px;
}

</style>