<template>
  <section class="contacts" id="contacts">
    <div class="wrapper">
      <h2>Contacte-nos</h2>
      <div class="contacts-left">
        <p>
          Aguardamos o seu contacto através do formulário disponível ou em
          alternativa através dos seguintes meios:<br /><br />Sede: Rua D. João
          V nº10 2ºDto<br />1250-090 Lisboa
        </p>
        <a href="tel:00351213832939">+351 213 832 939*</a>
        <a href="tel:00351963574306">+351 963 574 306</a>
        <a class="bold" href="mailto:geral@loop.pt">geral@loop.pt</a>
        <p class="note">*Chamada para a rede fixa nacional</p>
      </div>
      <div class="contacts-right">
        <div class="form">
          <h3>Preencha com os seus dados<br />e entraremos em contacto</h3>
          <!-- <div class="inputGroup">
            <label>Assunto</label>
            <select>
              <option value="Opção A">Opção A</option>
              <option value="Opção B">Opção B</option>
              <option value="Opção C">Opção C</option>
            </select>
          </div>
          <div class="inputGroup">
            <label>Email</label>
            <input type="email" id="email" tarefa="" required />
          </div>
          <div class="inputGroup">
            <label>Telefone</label>
            <input type="tel" id="phone" tarefa="" required />
          </div>
          <div class="inputGroup">
            <label>Mensagem</label>
            <textarea id="mensage" rows="4" tarefa="" required></textarea>
          </div> -->
          <v-row>
            <v-col cols="12">
              <v-select
                :items="subjects"
                label="Assunto"
                v-model="subject"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Email" v-model="email"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Telefone" v-model="phone"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea label="Mensagem" v-model="msg" rows="3"></v-textarea>
            </v-col>
            <!-- <v-col cols="12" v-if="subject == 'Recrutamento'">
              <v-file-input
                v-model="cv"
                label="Selecionar CV"
              ></v-file-input>
            </v-col> -->
            <v-col cols="12">
              <v-checkbox v-model="checkbox">
                <template v-slot:label>
                  <div>
                    Declaro que li e aceito a Política de Protecção de Dados.
                  </div>
                </template>
              </v-checkbox>
            </v-col>
            <p class="error-msg">{{ errMsg }}</p>
          </v-row>
          <a @click="submeter()" class="button-c">Enviar</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import computedMixins from "@/mixins/computed";
import axios from "axios";

export default {
  mixins: [computedMixins],
  data() {
    return {
      subject: "",
      email: "",
      phone: "",
      msg: "",
      cv:"",
      checkbox: false,
      errMsg: "",
      subjects: [
        "Pedido de Informações",
        "Pedido de Cotação",
        // "Recrutamento",
        "Outro",
      ],
    };
  },
  methods: {
    // async submeter() {
    //   event.preventDefault()


    //   this.errMsg = ""

    //   let errorMsgs = {
    //       subject: "Introduza o assunto da sua mensagem",
    //       name: "Introduza o seu nome",
    //       email: "Introduza o seu email",
    //       msg: "Escreva a sua mensagem",
    //       checkbox:"Para enviar a sua mensagem terá que declarar que leu e aceitou a Política de Protecção de Dados",
    //       success: "A sua mensagem foi enviada com sucesso! Será contactado brevemente",
    //       fail: "Houve um erro no envio da sua mensagem. Por favor tente novamente mais tarde ou entre em contacto através de um dos canais alternativos",
    //       sending: "A enviar..."
    //   } 

    //   if(this.subject == "") {
    //     this.errMsg = errorMsgs.subject
    //   }
    //   else if(this.name == "") {
    //     this.errMsg = errorMsgs.name
    //   }
    //   else if(this.email == "") {
    //     this.errMsg = errorMsgs.email
    //   }
    //   else if(this.msg == "") {
    //     this.errMsg = errorMsgs.msg
    //   }
    //   else if(this.checkbox == false) {
    //     this.errMsg = errorMsgs.checkbox
    //   }

    //   else {
    //     this.errMsg = errorMsgs.sending

    //     let mensagem = `
    //       <p><b>Assunto: </b>${this.subject}</p>
    //       <p><b>Email: </b>${this.email}</p>
    //       <p><b>Telefone: </b>${this.phone}</p>
    //       <p><b>Mensagem: </b><br>${this.msg}</p>
    //     `

    //     let fd = new FormData()

    //     fd.append('mensagem', mensagem)

    //     var myHeaders = new Headers();

    //     var myInit = { 
    //       method: 'POST',
    //       headers: myHeaders,
    //       body: fd
    //     };

    //     let app = this

    //     fetch('/base_mailer/', myInit)
    //     .then(async function(response) {
    //       let res = await response.text()

    //       console.log(res)

    //       if(res == "OK") {
    //         app.errMsg = errorMsgs.success
    //       }
    //       else {
    //         app.errMsg = errorMsgs.fail
    //       }
    //     })

    //   }
    // },
    async submeter() {
      event.preventDefault()
      this.errorFontColor = ""
      this.errMsg = "A enviar"

      let dados = {
        assunto: this.subject,
        email: this.email,
        msg: this.msg,
        checkbox: this.checkbox,
        phone: this.phone,
        form: "Contactos"
      };

      if(dados.phone == ""){
        dados.phone = "Não enviou número de telefone"
      }

    if (dados.assunto == "") {
        this.errorFontColor = "red"
        this.errMsg = "Introduza o assunto da sua mensagem";
        return;
      } else if (dados.email == "") {
        this.errorFontColor = "red"
        this.errMsg = "Introduza o seu email";
        return;
      } else if (dados.msg == "") {
        this.errorFontColor = "red"
        this.errMsg = "Escreva a sua mensagem";
        return;
      } else if (!dados.checkbox) {
        this.errorFontColor = "red"
        this.errMsg = "Para enviar a sua mensagem terá que declarar que leu e aceitou a Política de Protecção de Dados";
        return;
      } else {
        try {
          const response = await axios.post(
            "https://loop.linqode.com/api/public/api/contacts/send",
            dados
          );

          if(response.data.resultado == "OK") {
            this.errorFontColor = ""
            this.errMsg = "A sua mensagem foi enviada com sucesso!";
          }
          else {
            this.errorFontColor = ""
            this.errMsg = response.data.details;
          }
        } catch (err) {
          if (err.response.status == 401) {
            this.errorFontColor = "red"
            this.errMsg = err.response.data.details;
          } else if(err.response.status == 204) {
            this.errorFontColor = ""
            this.errMsg = err.response.data.details;
          }
          else {
            this.errorFontColor = "red"
            this.errMsg = "Alguma coisa falhou. Por favor tente mais tarde";
          }
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.contacts {
  background-color: $light-grey;
  padding: 50px 0;
  font-size: 14px;
  h2 {
    margin-bottom: 50px;
    @include responsive(0, 768px) {
      margin-bottom: 20px;
    }
  }
  .contacts-left {
    width: 30%;
    display: inline-block;
    vertical-align: top;
    margin-left: 20%;
    margin-top: 45px;
    padding-right: 30px;
    @include responsive(0, 768px) {
      width: 100%;
      margin-left: 0;
      margin-top: 0px;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 20px;
      @include responsive(0, 768px) {
        text-align: center;
      }
      &.note{
        font-size: 12px;
        margin-top: 15px;
      }
    }
    a {
      font-size: 14px;
      line-height: 20px;
      display: block;
      text-decoration: none;
      color: $color-black;
      @include responsive(0, 768px) {
        text-align: center;
      }
      &.bold {
        margin-top: 20px;
      }
    }
  }
  .contacts-right {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    @include responsive(0, 768px) {
      width: 100%;
    }
    .form {
      background-color: white;
      padding: 40px 30px;
      width: 400px;
      -webkit-box-shadow: 2px 2px 5px 0px rgba(153, 150, 153, 0.8);
      -moz-box-shadow: 2px 2px 5px 0px rgba(153, 150, 153, 0.8);
      box-shadow: 2px 2px 5px 0px rgba(153, 150, 153, 0.8);
      @include responsive(0, 768px) {
        width: 100%;
      }
      h3 {
      }
      .button-c {
        margin: 0 auto;
        margin-top: 10px;
      }
    }
  }
}

.v-input--checkbox {
  div {
    font-size: 12px;
  }
}

.error-msg {
  font-size: 12px;
  color: $red;
  font-family: $font1;
  font-weight: 500;
}
</style>