export default {
    computed: {
        img_url() {
            return this.$store.state.img_domain;
        },

        selectedProductId(){
            return this.$store.state.selectedProductId;
        }
    },
}