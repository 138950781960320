import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    img_domain: 'https:///loop.linqode.com/images/',
    selectedProductId: 0,
  },
  mutations: {
    UPDATE_PROD_ID(state, payload) {
      console.log(payload.id)
      state.selectedProductId = parseInt(payload.id)
  }
  },
  actions: {
    async updateProdId(context, { app }) {
      console.log('updateProdId: ' + app.product.id)
      
      await context.commit('UPDATE_PROD_ID', app.product)
    }
  },
  modules: {
  }
})
