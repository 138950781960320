<template>
  <section class="about" id="about">
    <div class="wrapper">
      <h2 class="loop-white">Sobre a Loop</h2>
      <p class="loop-white">
        A LOOP Projecto e Comércio Internacional Lda, foi fundada em 1985 com o
        foco na instrumentação industrial e com o objetivo de acompanhar os seus
        clientes na escolha e definição dos equipamento que maior benefício
        oferecem às suas aplicações.
      </p>
      <p class="loop-white">
        Há mais de 35 anos que apoiamos os nossos clientes, providenciando um
        leque extenso de soluções que englobam medição de nível, pressão,
        caudal, concentração, turbidez, registo e comunicações digitais,
        protecção das instalações com produtos voláteis ou inflamáveis, entre
        outras, todas elas de extrema importância para a otimização dos
        processos, quer na vertente económica, quer, também, na não menos
        importante vertente da segurança e impacto ambiental.
      </p>
      <p class="loop-white">
        Tendo construído sólidas parcerias, algumas com mais de 30 anos, com
        reconhecidos fabricantes mundiais, a LOOP garante equipamentos de
        elevada qualidade, com as mais recentes tecnologias, de forma a servir
        uma vasta gama de industrias e atividades, ( química, petroquímica,
        alimentar e bebidas, energia, águas, águas residuais, papel, entre
        outras) dando uma resposta altamente eficaz mesmo nas aplicações de
        elevado grau de exigência.
      </p>
      <p class="loop-white">
        O seu corpo técnico é composto por uma equipa experiente e tecnicamente
        preparada para solucionar os problemas dos nossos clientes, tanto na
        definição da especificação técnica dos equipamentos a fornecer, como
        garantindo igualmente o suporte e manutenção desses mesmos equipamentos.
      </p>
      <p class="loop-white">
        Oferecer aos nossos clientes e parceiros a confiança que o prestígio nos
        confere, continuará a ser sempre o nosso lema.
      </p>
      <!-- <div class="cronograma">
				<span class="row"></span>
				<ul>
					<li class="right">1900 Fundação</li>
					<li class="left">1900 Marco 1</li>
					<li class="right">1900 Marco 1</li>
					<li class="left">1900 Marco 1</li>
					<li class="right">1900 Nova Marca</li>
				</ul>
			</div> -->
    </div>
  </section>
</template>

<script>

export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.about {
  background: url(https://tools.linqode.com/tools/assets/loop/images/padrao-bg.jpg);
  background-position: center center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 0;
  text-align: center;
  @include responsive(0, 512px) {
    padding: 40px 0;
  }
  h2 {
  }
  p {
    margin: 0 auto;
    max-width: 1000px;
    text-align: center;
    font-weight: $regular;
    margin-bottom: 20px;
  }
  .cronograma {
    position: relative;
    padding: 10px;

    .row {
      width: 2px;
      height: calc(100% - 15px);
      display: block;
      background-color: white;
      position: absolute;
      top: 15px;
      left: calc(50% + 5px);
    }
    ul {
      padding-bottom: 20px;
      li {
        font-size: 18px;
        color: white;
        width: 50%;
        margin-bottom: 30px;
        font-weight: $bold;
        @include responsive(0, 512px) {
          font-size: 14px;
        }
      }
      .right {
        position: relative;
        left: 50%;
        text-align: left;
        @include responsive(0, 512px) {
          padding-left: 20px;
        }
        &:before {
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: white;
          vertical-align: middle;
          margin-right: 15px;
          @include responsive(0, 512px) {
            margin-left: -20px;
          }
        }
      }
      .left {
        position: relative;
        right: -12px;
        text-align: right;
        &:after {
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: white;
          vertical-align: middle;
          margin-left: 15px;
        }
      }
    }
  }
}
</style>