<template>
  <section class="search" id="search">
    <div class="wrapper">
      <h2>Produtos</h2>
      <p>
        Filtre por Aplicação, Função ou Marca para encontrar mais facilmente o
        equipamento necessário à sua aplicação
      </p>
      <ul class="filters">
        <li>
          <v-select
            :items="applications"
            item-text="text"
            item-value="value"
            label="Aplicação"
            v-model="application"
            outlined
          ></v-select>
        </li>
        <li>
          <v-select
            :items="functions"
            item-text="text"
            item-value="value"
            label="Função"
            v-model="func"
            menu-props="auto"
            outlined
          ></v-select>
        </li>
        <li>
          <v-select
            :items="brands"
            item-text="text"
            item-value="value"
            label="Marcas"
            v-model="brand"
            outlined
          ></v-select>
        </li>
      </ul>
      <ul class="search-list" id="itemContainer">
        <li v-for="(product, i) in products" :key="i">
          <ProductPreview
            :product="product"
            v-if="i <= max_page && i >= min_page"
          />
        </li>
      </ul>
      <div class="text-center">
        <v-pagination v-model="page" :length="num_pages" circle></v-pagination>
      </div>
      <div class="holder"></div>
    </div>
  </section>
</template>

<script>
import ProductPreview from "@/components/product_preview";
import computedMixins from "@/mixins/computed";

export default {
  mixins: [computedMixins],
  props: ["all_products"],
  components: {
    ProductPreview,
  },

  data() {
    return {
      page: 1,
      func: "Todas",
      application: "Todas",
      brand: "Todas",
    };
  },
  methods: {},
  computed: {
    num_pages() {
      let pages = 1;
      if (this.products.length > 8) {
        pages = parseInt(this.products.length / 8) + 1;
      } else {
        pages = 1;
      }
      return pages;
    },
    max_page() {
      let max = this.page * 8 - 1;
      return max;
    },
    min_page() {
      let min = this.max_page - 7;
      return min;
    },
    functions() {
      let temp = [];
      if (this.application == "Todas" && this.brand == "Todas") {
        temp = [
          ...new Set(
            this.all_products.map((item) => {
              let value = item.funcao_id.toLowerCase().trim();
              let text = item.funcao_name.trim();

              return { text, value };
            })
          ),
        ];
      } else {
        temp = [
          ...new Set(
            this.products.map((item) => {
              let value = item.funcao_id.toLowerCase().trim();
              let text = item.funcao_name.trim();

              return { text, value };
            })
          ),
        ];
      }

      temp.sort((a, b) =>
        a.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
        b.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          ? 1
          : -1
      );

      temp.unshift("Todas");

      return temp;
    },
    applications() {
      let temp = [];
      if (this.func == "Todas" && this.brand == "Todas") {
        temp = [
          ...new Set(
            this.all_products.map((item) => {
              let value = item.app_id.toLowerCase().trim();
              let text = item.app_name.trim();

              return { text, value };
            })
          ),
        ];
      } else {
        temp = [
          ...new Set(
            this.products.map((item) => {
              let value = item.app_id.toLowerCase().trim();
              let text = item.app_name.trim();

              return { text, value };
            })
          ),
        ];
      }

      temp.sort((a, b) =>
        a.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
        b.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          ? 1
          : -1
      );

      temp.unshift("Todas");

      return temp;
    },
    brands() {
      let temp = [];
      if (this.func == "Todas" && this.application == "Todas") {
        temp = [
          ...new Set(
            this.all_products.map((item) => {
              let value = item.marca_id.toLowerCase().trim();
              let text = item.marca_name.trim();

              return { text, value };
            })
          ),
        ];
      } else {
        temp = [
          ...new Set(
            this.products.map((item) => {
              let value = item.marca_id.toLowerCase().trim();
              let text = item.marca_name.trim();

              return { text, value };
            })
          ),
        ];
      }

      temp.sort((a, b) =>
        a.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
        b.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          ? 1
          : -1
      );

      temp.unshift("Todas");

      return temp;
    },
    products() {
      let temp = this.all_products;
      let app = this;

      if (this.func != "Todas") {
        temp = [...new Set(temp.filter((item) => item.funcao_id == app.func))];
      }
      if (this.application != "Todas") {
        temp = [
          ...new Set(temp.filter((item) => item.app_id == app.application)),
        ];
      }
      if (this.brand != "Todas") {
        temp = [...new Set(temp.filter((item) => item.marca_id == app.brand))];
      }

      return temp;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.search {
  padding: 70px 0;
  background-color: $light-grey;
  &.all {
    margin-top: 90px;
    @include responsive(0, 768px) {
      margin-top: 70px;
    }
  }
  @include responsive(0, 512px) {
    padding: 40px 0;
  }
  h2 {
  }

  p {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-bottom: 30px;
    @include responsive(0, 768px) {
      width: 100%;
    }
  }
  .filters {
    text-align: center;
    padding-left: 0 !important;

    li {
      display: inline-block;
      max-width: 300px;
      width: 100%;
      margin-right: 20px;
      margin-left: 20px;
      @include responsive(0, 768px) {
        display: block;
        margin: 0;
      }
    }
  }
  .search-list {
    margin-top: 50px;
    padding-left: 0 !important;
    li {
      width: 25%;
      display: inline-block;
      vertical-align: top;
      @include responsive(0, 900px) {
        width: 33.3%;
      }
      @include responsive(0, 768px) {
        width: 50%;
      }
      @include responsive(0, 512px) {
        width: 95%;
      }
      article {
        padding: 0 5%;
        a {
          text-decoration: none;
          &:hover {
            .img-bg {
              -webkit-box-shadow: 1px 1px 0px 0px rgba(153, 150, 153, 0.3);
              -moz-box-shadow: 1px 1px 0px 0px rgba(153, 150, 153, 0.3);
              box-shadow: 1px 1px 0px 0px rgba(153, 150, 153, 0.3);
            }
          }
          .img-bg {
            width: 100%;
            height: 150px;
            background-color: white;
            -webkit-box-shadow: 2px 2px 5px 0px rgba(153, 150, 153, 0.8);
            -moz-box-shadow: 2px 2px 5px 0px rgba(153, 150, 153, 0.8);
            box-shadow: 2px 2px 5px 0px rgba(153, 150, 153, 0.8);
            position: relative;
            text-align: center;
            overflow: hidden;
            @include responsive(0, 512px) {
              width: 100%;
              margin: 0 auto;
            }
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: auto;
              height: 90%;
            }
          }
          h4 {
            margin-top: 10px;
          }
          div {
            @include tp-font-size(14px, 14px, 14px, 13px, 13px, 13px, 13px);
            @include tp-line-height(20px, 20px, 20px, 18px, 18px, 18px, 18px);
            //@include tp-margin-bottom ($margin-bottom,$w1440,$w1024,$w900,$w768,$w512,$w380);
            font-family: $font2;
            font-weight: $light;
            color: $color-black;
            text-align: left;
            text-transform: none;
            text-align: left;
            width: 100%;
            margin: 5px 0 40px 0;

            div {
              @include tp-font-size(14px, 14px, 14px, 13px, 13px, 13px, 13px);
              @include tp-line-height(20px, 20px, 20px, 18px, 18px, 18px, 18px);
              //@include tp-margin-bottom ($margin-bottom,$w1440,$w1024,$w900,$w768,$w512,$w380);
              font-family: $font2;
              font-weight: $light;
              color: $color-black;
              text-align: left;
              text-transform: none;
              text-align: left;
              @include responsive(0, 512px) {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.holder {
  margin: 0 auto;
  text-align: center;
  a {
    font-size: 16px;
    margin: 10px;
    font-family: $font2;
    line-height: 20px;
    cursor: pointer;
  }
}

.v-pagination__navigation {
  width: 25px !important;
  height: 25px !important;
  box-shadow: none !important;
  background: transparent !important;
}

.v-pagination__item {
  min-width: 25px !important;
  height: 25px !important;
  font-size: 14px !important;
  box-shadow: none !important;
  background: transparent !important;
}
</style>