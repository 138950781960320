<template>
  <div class="main-container">
    <Banner :slides="slides"/>
    <Search :all_products="all_products"/>
    <About />
    <Brands :brands="brands"/>
    <Contacts/>
  </div>
</template>

<script>
import computedMixins from "@/mixins/computed";

import Banner from "@/components/banner";
import Search from "@/components/produtos";
import About from "@/components/about";
import Brands from "@/components/brands";
import Contacts from "@/components/contacts";

export default {
  mixins: [computedMixins],
  props: ["all_products", "slides", "brands"],
  components: {
    Banner,
    Search,
    About,
    Brands,
    Contacts,
  },
  name: "Home",
  data() {
    return {};
  },
  
  methods: {
    
  },

};
</script>
