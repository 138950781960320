<template>
  <v-carousel hide-delimiters :show-arrows="slides.length > 1" height="auto">
    <v-carousel-item v-for="(slide, i) in slides" :key="i">
      <div class="slider-container slider" id="divDestaques">
        <div class="li-slider" id="divDestaques">
          <article>
            <div class="article-left">
              <h1 v-html="slide.titulo"></h1>
              <p v-html="slide.description"></p>
              <a
                class="button-a"
                :href="slide.link"
                target="_blank"
                v-if="slide.link != ''"
                >Saiba Mais</a
              >
            </div>
            <div class="article-right">
              <div class="img-container">
                <img alt="" :src="img_url + slide.img" />
              </div>
            </div>
          </article>
        </div>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import computedMixins from "@/mixins/computed";

export default {
  mixins: [computedMixins],
  props: ["slides"],
  data() {
    return {
      isActive: true,
    };
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

article {
  .article-left {
    position: relative;
    padding-top: 110px;
    width: 40%;
    display: inline-block;
    margin-left: 10%;
    @include responsive(0, 768px) {
      width: 80%;
      padding-top: 60px;
      padding-bottom: 40px;
    }
    h1 {
      width: 90%;
    }
    p {
      width: 90%;
      margin-bottom: 30px;
    }
    a {
    }
  }
  .article-right {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    top: 0px;
    right: 0;
    @include responsive(0, 768px) {
      width: 100%;
    }
    .img-container {
      overflow: hidden;
      border-top-left-radius: 12.5vw;
      border-bottom-left-radius: 12.5vw;
      height: 25vw;
      background-color: $light-grey;
      @include responsive(0, 768px) {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        height: auto;
      }
      img {
        height: 100%;
        position: relative;
        left: 0;
        top: 0;
        @include responsive(0, 768px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>