import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: "#c82027",
            secondary: "#7e1416",
            accent: "#c82027",
          },
        },
      },
});

var VueScrollTo = require('vue-scrollto');

 
Vue.use(VueScrollTo)

Vue.use(VueScrollTo, {
    container: "body",
    duration: 600,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})
