<template>
  <v-app>
    <Loader :loader="loader"/>
    <section class="header" id="header">
        <div class="logo">
          <router-link
            :to="{ path: '/', hash: 'header' }"
            v-scroll-to="{ el: '#header' }"
            ><img alt="logo-loop" src="@/assets/images/logo.png"
          /></router-link>
        </div>
        <div class="nav">
          <ul>
            <li class="navLink">
              <router-link :to="{ path: '/produtos', hash: 'search' }"
                >Produtos</router-link
              >
            </li>
            <li class="navLink">
              <router-link :to="{ path: '/', hash: 'about' }"
                >Sobre</router-link
              >
            </li>
            <li class="navLink">
              <router-link :to="{ path: '/', hash: 'brands' }"
                >Marcas</router-link
              >
            </li>
            <li class="navLink">
              <router-link :to="{ path: '/', hash: 'contacts' }"
                >Contactos</router-link
              >
            </li>
          </ul>
        </div>
    </section>
    <v-main>
      <router-view
        :all_products="all_products"
        :slides="slides"
        :brands="brands"
      />
    </v-main>
    <section class="footer" id="footer">
        <div class="logo-white">
          <router-link
            :to="{ path: '/', hash: 'header' }"
            v-scroll-to="{ el: '#header' }"
            ><img alt="logo-loop" src="@/assets/images//logo-white.png"
          /></router-link>
        </div>
        <div class="rights">
          <p>
            <a v-on:click="terms = 'open'">Política de Protecção de Dados</a>
          </p>
          <p>
            Todos os direitos reservados @ Loop {{ new Date().getFullYear() }}
          </p>
        </div>
    </section>
    <Terms v-if="terms == 'open'" @open-terms="updateTerms"/>
  </v-app>
</template>

<script>
import EventService from "@/services/EventService";
import computedMixins from "@/mixins/computed";
import Loader from "@/components/loader";
import Terms from "@/components/terms";

export default {
  name: "App",
  mixins: [computedMixins],
  components: {
    Loader,
    Terms
  },

  data: () => ({
    all_products: [],
    slides: [],
    brands: [],
    loader:true,
    drawer: null,
    terms: '',
  }),

  created() {
    let app = this 

    EventService.getProducts("0", "0", "0").then(
      (response) => (this.all_products = response.data)
    );
    EventService.getBanners().then(
      (response) => (this.slides = response.data.resultado)
    );
    EventService.getBrands().then(
      (response) => (this.brands = response.data.resultado)
    );
    setTimeout(function(){
      app.loader = false
    }, 2000)
  },
  methods:{
    updateTerms: async function (value) {
      this.n += 1;
      this.terms = value;
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.main-container {
  margin-top: 90px;
  @include responsive(0, 512px) {
    margin-top: 70px;
  }
}

.header {
  width: 100%;
  height: 90px;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(153, 150, 153, 0.5);
  -moz-box-shadow: 0px 1px 2px 0px rgba(153, 150, 153, 0.5);
  box-shadow: 0px 1px 2px 0px rgba(153, 150, 153, 0.5);
  z-index: 100;
  padding: 0 50px;
  @include responsive(0, 512px) {
    height: 70px;
    padding: 0 20px;
  }
  .logo {
    display: inline-block;
    width: 30%;
    @include responsive(0, 512px){
      width: 20%;
    }
    img {
      height: 40px;
      margin-top: 25px;
      @include responsive(0, 512px) {
        height: 20px;
      }
    }
  }
  .nav {
    display: inline-block;
    width: 70%;
    text-align: right;
    line-height: 90px;
    vertical-align: top;
    @include responsive(0, 512px){
      width: 80%;
          line-height: 70px;

    }
    ul {
      li {
        display: inline-block;
        margin-left: 20px;
        @include responsive(0, 512px) {
          margin-left: 10px;
        }
        a {
          text-decoration: none;
          color: $color-black;
          font-size: 14px;
          font-weight: $bold;
          text-transform: uppercase;
          @include responsive(0, 768px) {
            font-size: 10px;
          }
          &:hover {
            color: red;
          }
        }
      }
    }
  }
}
  

.footer {
  width: 100%;
  background-color: $color-black;
  height: 80px;
  padding: 0 50px;
  @include responsive(0, 768px) {
    padding: 0 10px;
    text-align: center;
    height: auto;
    padding-bottom: 20px;
  }
  .logo-white {
    width: 50%;
    display: inline-block;
    @include responsive(0, 768px) {
      width: 100%;
    }
    img {
      height: 40px;
      margin-top: 20px;
      @include responsive(0, 768px) {
        height: 30px;
        margin-top: 25px;
      }
    }
  }
  .rights {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    margin-top: 30px;
    text-align: right;
    @include responsive(0, 768px) {
      margin-top: 25px;
      width: 100%;
      text-align: center;
    }
    p {
      display: inline-block;
      margin-left: 20px;
      color: white;
      font-size: 12px;
      @include responsive(0, 768px) {
        display: block;
        text-align: center;
        margin-left: 0;
        margin-bottom: 5px;
      }
      a {
        color: white;
        text-decoration: underline;
      }
    }
  }
}

#politica_dados {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  .bg {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.8;
    z-index: 200;
  }
  #close {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    z-index: 210;
    img {
      width: 100%;
    }
  }
  .container {
    position: relative;
    width: 80%;
    background-color: white;
    margin: 40px auto;
    padding: 50px;
    text-align: center;
    z-index: 210;
    border-radius: 10px;
    height: 90vh;
    overflow: auto;
    @include responsive(0, 512px) {
      padding: 20px;
      width: 90%;
      margin-top: 50px;
    }
    h2 {
      font-size: 22px;
      color: $red;
      text-align: left;
      @include responsive(0, 512px) {
        font-size: 18px;
      }
    }
    h3 {
      margin-top: 20px;
      text-align: left;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 20px;
      a {
        color: $color-black;
      }
    }
  }
}
</style>