import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },{
    path: '/produtos',
    name: 'Produtos',
    component: () =>
        import ('../views/Search.vue')
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash
        , offset: { x: 0, y: 70 },
        behavior: 'smooth',
      }
    }
  }
})

export default router
