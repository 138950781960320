<template>
  <article>
    <router-link :to="'/produtos/#product' + product.id">
    <a v-on:click="updateProductId">
      <div class="img-bg"><img alt="" :src="img_url + product.img" /></div>
      <h4 v-html="product.name"></h4>
      <div v-html="product.short_description"></div>
    </a></router-link>
  </article>
</template>

<script>
import computedMixins from "@/mixins/computed";

export default {
    mixins: [computedMixins],
    props:["product"],
    data(){
      return{
        product_selected: 0,
      }
    },
    methods:{
      async updateProductId(){
        let app = this
        this.$store.dispatch('updateProdId', {app})
      }
    }
};
</script>

<style lang="scss" scoped>

</style>